<template lang="pug">
	div(class="ToursCategory")

		h2(class="ToursCategory__title") {{title}}

		v-layout(v-if="areToursLoaded" row wrap class="ToursCategory__content")
			v-flex(
				xs12
				sm6
				md4
				lg3
				v-for="tour in tours"
				:key="tour.id"
			)
				tour(:promoted="isPromoted" :tour="tour" @click="viewTour(tour)")

		v-layout(v-else row wrap class="ToursCategory__content")
			v-flex(
				v-for="(tour, i) in [1,2,3,4]"
				:key="i"
				xs12
				sm6
				md4
				lg3
			)
				tour(loading)
</template>

<script>
import Tour from '@/modules/tours/components/Tour';
import {queries} from '@/modules/tours/services'
import {subDays} from 'date-fns'

import random from 'lodash.random';

export default {
	name: 'ToursCategory',

	data() {
		return {
			tours: [],
			isPromoted: Math.random() >= 0.8,
			areToursLoaded: false,
			fakeTours: [{}, {}, {}, {}]
		}
	},

	props: {
		title: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: true
		},
		date: {
			type: Boolean,
			default: false
		},
		random: {
			type: Boolean,
			default: false
		},
		hot: {
			type: Boolean,
			default: false
		},
		limit: {
			type: Number,
			default: 10
		},
		order: {
			type: String,
			default: 'desc'
		},
		category: {
			type: String
		}

	},

	components: {
		Tour
	},

	mounted() {
		let request;
		const now = new Date();
		const params = {
			order: this.order,
			limit: this.limit
		};

		if (this.date) {
			request = queries.getTours(params);
		} else if(this.random) {
			const randomDays = random(0, 500);
			params.date = subDays(now, randomDays).toISOString();
			request = queries.getToursByDate(params);
		} else if(this.hot) {
			request = queries.getHotTours(params);
		} else if(this.category) {
			params.category = this.category;
			request = queries.getToursByCategory(params);
		} else {
			request = queries.getTours(params);
		}

		this.loading = true;
		request
			.then(this.handleRequest.bind(this))
			.catch(this.handleError.bind(this));

	},

	methods: {
		handleError(e) {
			this.$notify({
				type: 'error',
				text: e.message
			});
		},

		handleRequest(tours) {
			this.tours = tours;
			this.areToursLoaded = true;
		},

		viewTour(tour) {
			this.$router.push({name: 'viewTour', params: {id: tour.id}})
		}
	},

	computed: {

	}
}
</script>

<style lang="less" scoped>
</style>

<template lang="pug">
	v-layout(class="Ads" row wrap)
		v-flex(xs7)
			v-img(class="Ads__tour" contain src="https://picsum.photos/1000/400/?random")
			
		v-flex(xs5 class="pl-5")
			v-layout(justify-center align-center fill-height class="Ads__ad")
				h2 Anunciate aquí
</template>

<script>
export default {
	name: 'Ads'
}
</script>

<style lang="less" scoped>
	.Ads {
		min-height: 450px;
	}
	.Ads__ad {
		background-color: #E0E0E0;
	}

	@radius: 90px;

	.Ads__tour {
		border-top-left-radius: @radius;
		border-bottom-right-radius: @radius;
	}
</style>

import firebase from '@/modules/common/firebase';

const firestore = firebase.firestore();
const tours = firestore.collection('tours');
const DEFAULT_LIMIT = 10;
const DEFAULT_ORDER = 'desc';
import {subDays} from 'date-fns'
import RNG from 'lodash.random';
const DAYS_YEAR = 365;

const transformTours = (snaps) => {
	const tours = [];

	snaps.forEach(snap => {
		const tour = snap.data();

		tour.id = snap.id;

		tours.push(tour);
	});

	return tours;
};

export default {
	getTours({order='desc', limit=DEFAULT_LIMIT}) {
		return tours
			.orderBy("dateCreated", order)
			.limit(limit)
			.get();
	},

	getToursByDate({order=DEFAULT_ORDER, limit=DEFAULT_LIMIT, date=(new Date()).toString()}) {
		return tours
			.orderBy("dateCreated", order)
			.startAt(date)
			.limit(limit)
			.get();
	},

	getToursByCategory({category, order=DEFAULT_ORDER, limit=DEFAULT_LIMIT}) {
		return tours
			.where('category', '==', category)
			.orderBy('dateCreated', order)
			.limit(limit)
			.get()
			.then(transformTours);
	},

	getHotTours({limit=DEFAULT_LIMIT}) {
		return tours
			.orderBy('hotness', DEFAULT_ORDER)
			.limit(limit)
			.get()
			.then(transformTours);
	},

	getMyTours({user}) {
		return tours.where('ownerId', '==', user.uid).get();
	},

	getTour(id) {
		return tours.doc(id).get();
	},

	getRandomTours({limit=DEFAULT_LIMIT}) {
		const randomDays = RNG(0, DAYS_YEAR * 3);
		const now = new Date();
		const date = subDays(now, randomDays).toISOString();

		return tours
			.orderBy("dateCreated", 'asc')
			.startAt(date)
			.limit(limit)
			.get()
			.then(transformTours);
	},

	async likeTour(tour) {

		const ref = await tours.doc(tour.id).get();

		const likes = ref.data().likes + 1;

		return ref.update({likes});
	}
};

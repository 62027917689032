<template lang="pug">
	v-content
		v-container(grid-list-xl)
			ads(class="hidden-sm-and-down")

			tours-category(
				title="Hot"
				icon="fire"
				class="mb-5"
				:limit="4"
				hot
			)

			tours-category(
				title="Inmobiliaria",
				icon="pine-tree"
				class="mt-5"
				:limit="4"
				category="real-state"
			)

			tours-category(
				title="Diseño 3D",
				icon="pine-tree"
				class="mt-5"
				:limit="4"
				category="3d-design"
			)

			tours-category(
				title="Hosteleria",
				icon="pine-tree"
				class="mt-5"
				:limit="4"
				category="hospitality"
			)

			tours-category(
				title="Personal",
				icon="pine-tree"
				class="mt-5"
				:limit="4"
				category="personal"
			)

			tours-category(
				title="Otros",
				icon="pine-tree"
				class="mt-5"
				:limit="4"
				category="other"
			)

</template>

<script>
import ToursCategory from '@/modules/tours/components/ToursCategory';
import Ads from '@/modules/tours/components/Ads';

export default {
	name: 'Home',
	data() {
		return {};
	},
	components: {
		ToursCategory,
		Ads
	}
}
</script>

<style lang="less" scoped>

</style>



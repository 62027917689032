import {END_POINTS} from '@/config';

const request = (path, method, data) => {
	return fetch(`${END_POINTS.tours}${path}`, {
		method,
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json'
		}
	}).then(res => res.json());
}

export const viewTour = (id) => {
	return request(`/${id}/view`, 'POST', {id});
};

export const likeTour = (id) => {
	return request(`/${id}/like`, 'POST', {id});
};

export const unlikeTour = (id) => {
	return request(`/${id}/like`, 'DELETE', {id});
};

export default {
	viewTour,
	likeTour,
	unlikeTour
}


